/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({title, description, path, year = '2022'}) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            baseUrl
            image
          }
        }
      }
    `
  )

  const defaults = data.site.siteMetadata
  const descriptionSEO = description ? description : defaults.description
  const titleSEO = title ? title : defaults.title
  const url = path ? `${defaults.baseUrl}${path}` : defaults.baseUrl
  const image = `${defaults.baseUrl}${year === '2022' ? '/share.jpg' : `/share-${year}.jpg`}`;
  
  return (
    <Helmet htmlAttributes={{
      lang: 'fr',
    }}>
      <title>{titleSEO}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={descriptionSEO} />
      {image && <meta name="image" content={image} />}
       
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={titleSEO} />
      <meta property="og:description" content={descriptionSEO} />
      {image && <meta property="og:image" content={image} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@big_youth" />
      <meta name="twitter:title" content={titleSEO} />
      <meta name="twitter:description" content={descriptionSEO} />
      {image && <meta name="twitter:image" content={image} />}

      <script data-cookieconsent="ignore" type="text/javascript">
        {`window.dataLayer = window.dataLayer || [];
          function gtag() {
              dataLayer.push(arguments);
          }
          gtag("consent", "default", {
              ad_storage: "denied",
              analytics_storage: "denied",
              wait_for_update: 500
          });
          gtag("set", "ads_data_redaction", true); `}
      </script>

      <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="0bbfc174-af64-432c-b46f-821dca9e709b" type="text/javascript" async></script>
      
      <script async>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MWGTM5C');`}</script>
    </Helmet>
  )
}

export default SEO
