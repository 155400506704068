import React, {useState, useEffect} from 'react'
import {Link} from 'gatsby'
import {useEventListener} from 'react-recipes'

import Share from './share'

import ArrowIcon from '../icons/arrow.svg'

const HeaderSimple = ({shareTxt, url, year}) => {
  const [isFixed, setIsFixed] = useState(false)
  const [offset, setOffset] = useState(40)

  const handleCalc = () => {
    if(typeof window !== 'undefined' && window.innerWidth >= 1024) {
      setOffset(40)
    } else {
      setOffset(1)
    }
  }

  useEffect(() => {
    setIsFixed(false)
    handleCalc()
  }, [])

  const handleScroll = () => {
    if(window.pageYOffset >= offset) {
      setIsFixed(true)
      document.querySelector('main').style.marginTop = '160px'
    } else {
      setIsFixed(false)
      document.querySelector('main').style.marginTop = '0'
    }
  }

  useEventListener('scroll', handleScroll)
  useEventListener('resize', handleCalc)

  return (
    <header className={`simple-header ${isFixed ? 'is-fixed' : ''}`}>
      <Link to={year !== '2022' ? `/${year}` : '/'} className="simple-header__side simple-header__back">
        <ArrowIcon />
        Accueil
      </Link>
      <div className="flex-grow simple-header__logo">
        <Link to={year !== '2022' ? `/${year}` : '/'}>
          <img width="280" src={`/images/logo${year === '2021' ? '-2021' : '' }.svg`} alt={`L'observatoire de l'impact positif ${year}`} />
        </Link>
      </div>
      <Share url={url} txt={shareTxt} year={year} />
    </header>
  )
}

export default HeaderSimple
