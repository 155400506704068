import * as React from 'react'
import {Link} from 'gatsby'

const Button = ({label, context = false, link, className, external = false, download = false}) => {
  const click = () => {
    window.dataLayer.push({
      'event': 'cta_click',
      'label': `${label}${context ? `- ${context}` : ''}`
    })
  }

  return (
    <>
      { external ?
        <>
          { download ?
            <a href={link} target="_blank" download rel="noopener noreferrer" className={className} onClick={click}>{label}</a>
          : <a href={link} target="_blank" rel="noopener noreferrer" className={className} onClick={click}>{label}</a>
          }
        </>
      : <Link to={link} className={className} onClick={click}>{label}</Link> }
    </>
  )
}

export default Button