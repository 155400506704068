import * as React from 'react'

const Header = ({year}) => (
  <header>
    <div className="header section-border-bottom">
      <div className="header__top">
        <img className="header__by" src="/images/logo-BY.svg" alt="Big Youth" width="128" height="40" />
        <span>&</span>
        <img className="header__occurrence" width="124" height="14" src="/images/logo-occurence.svg" alt=" Occurence" />
      </div>
      <p className="header__bottom">présentent</p>
    </div>

    <div className="fluid-grid">
      <img className="header__logo" width="788" height="310" src={`/images/logo${year === '2021' ? '-2021' : '' }.svg`} alt={`L'observatoire de l'impact positif ${year}`} />
      <h1 className="sr-only">L'observatoire { year } de l'impact positif</h1>
    </div>
  </header>
)

export default Header
