/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import {Link} from 'gatsby'

import '../styles/styles.scss'

import Header from "./header"
import Button from './button'
import HeaderSimple from "./headerSimple"

const Layout = ({ children, simpleHeader = false, shareTxt, url, type = 'all', year = '2022' }) => {
  return (
    <>
      <div className="site-wrapper">
        <div className="site-wrapper__inner">
          <div>
            { simpleHeader ?
              <HeaderSimple url={url} shareTxt={shareTxt} year={year} />
            : <Header year={year} /> }
          
            <main>
              {children}
            </main>
          </div>

          <footer className="footer">
            <div className="fluid-grid">
              <div className="row">
                <div className="lg-col-1 footer__logo">
                  <Link to={year !== '2022' ? `/${year}/` : '/'}><img className="mx-auto mb-10 lg:mb-0" src={`/images/logo${year === '2021' ? '-2021' : '' }.svg`} alt={`L'observatoire de l'impact positif ${year}`} /></Link>

                  {year === '2021'
                   ? <Button link="/"  label="Voir l'édition 2022" className="button mb-10 lg:mb-0 lg:mt-10" />
                   : <Button link="/2021"  label="Voir l'édition 2021" className="button mb-10 lg:mb-0 lg:mt-10" />
                  } 
                </div>

                { (type === 'all' || type === 'index') &&
                  <div className="lg-col-1 footer__list">
                    <p className="label-filter mb-6">Tous les objectifs :</p>

                    <ul>
                      <li className="mr-4">
                        <Button className="label footer__link" label="Utilisabilité" context="footer" link={ `${year !== '2022' ? `/${year}/` : '/' }utilisabilite`} />
                      </li>
                      <li className="mr-4">
                        <Button className="label footer__link" label="Écoconception" context="footer" link={ `${year !== '2022' ? `/${year}/` : '/' }ecoconception`} />
                      </li>
                      <li className="mr-4">
                        <Button className="label footer__link" label="Accessibilité" context="footer" link={ `${year !== '2022' ? `/${year}/` : '/' }accessibilite`} />
                      </li>
                      <li className="mr-4">
                        <Button className="label footer__link" label="Efficacité SEO" context="footer" link={ `${year !== '2022' ? `/${year}/` : '/' }efficacite-seo`} />
                      </li>
                      { type === 'all' &&
                        <li>
                        <Button className="label footer__link" label="Classement" context="footer" link={ `${year !== '2022' ? `/${year}/` : '/' }classement`} />
                        </li>
                      }
                    </ul>
                  </div>
                }
                
                { (type === 'all' || type === 'sector') &&
                  <div className="lg-col-1 footer__list">
                    <p className="label-filter mb-6">Tous les secteurs :</p>

                    <ul>
                      <li className="mr-4">
                        <Button className="label footer__link" label="Distribution spécialisée" context="footer" link={ `${year !== '2022' ? `/${year}/` : '/' }distribution-specialisee`} />
                      </li>
                      <li className="mr-4">
                        <Button className="label footer__link" label="Grande distribution" context="footer" link={ `${year !== '2022' ? `/${year}/` : '/' }grande-distribution`} />
                      </li>
                      <li className="mr-4">
                        <Button className="label footer__link" label="Luxe" context="footer" link={ `${year !== '2022' ? `/${year}/` : '/' }luxe`} />
                      </li>
                      <li className="mr-4">
                        <Button className="label footer__link" label="Beauté" context="footer" link={ `${year !== '2022' ? `/${year}/` : '/' }beaute`} />
                      </li>
                      <li className="mr-4">
                        <Button className="label footer__link" label="Automobile" context="footer" link={ `${year !== '2022' ? `/${year}/` : '/' }automobile`} />
                      </li>
                      <li className="mr-4">
                        <Button className="label footer__link" label="Telecoms" context="footer" link={ `${year !== '2022' ? `/${year}/` : '/' }telecom`} />
                      </li>
                    </ul>
                  </div>
                }
              </div>
            </div>
            
            <div className="footer__bottom has-border-top">
              <div className="fluid-grid md:flex justify-between items-center">
                <p>
                  © {new Date().getFullYear()} - <Link to={ `${year !== '2022' ? `/${year}/` : '/' }credits-et-mentions-legales`}>Crédits & Mentions légales </Link>
                  {/* eslint-disable-next-line */}
                   - <Link to={ `${year !== '2022' ? `/${year}/` : '/' }demarche-accessibilite`}>Accessibilité</Link> - <Link to="/vos-donnees">Vos données</Link> - <button onClick={() => Cookiebot.renew()}>Gestion de vos cookies</button>
                </p>

                <p className="flex items-center mt-4 md:mt-0 flex-shrink-0">Une réalisation <a href="https://www.bigyouth.fr/" target="_blank" rel="noopener noreferrer"><img className="ml-2 lazyload" width="85" height="26" data-src="/images/logo-BY.svg" alt="Big Youth" /></a></p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  )
}

export default Layout
