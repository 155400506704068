import React, {useState} from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ShareIcon from '../icons/share.svg'
import FacebookIcon from '../icons/facebook.svg'
import LinkedinIcon from '../icons/linkedin.svg'
import TwitterIcon from '../icons/twitter.svg'
import LinkIcon from '../icons/link.svg'

const Share = ({url, txt, year = '2022'}) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            baseUrl
            image
          }
        }
      }
    `
  )

  const [isOpen, setIsOpen] = useState(false)
  const [activeMessageCopy, setActiveMessageCopy] = useState(false)

  const pageUrl = url ? `${data.site.siteMetadata.baseUrl}${url}` : data.site.siteMetadata.baseUrl
  const pageTxt = txt ? txt : `Découvrez l'observatoire ${year} de l'impact positif`

  const copyLink = () => {
    const body = document.querySelector('body')
    const _tempInput = document.createElement('INPUT')
    body.appendChild(_tempInput)
    _tempInput.setAttribute('value', pageUrl)
    _tempInput.select()
    document.execCommand('copy')
    body.removeChild(_tempInput)

    setActiveMessageCopy(true)

    setTimeout(() => {
      setActiveMessageCopy(false)
    }, 1000)
  }

  const handleClick = (e) => {
    setIsOpen(!isOpen)

    const value = (e.target.getAttribute('aria-expanded') === 'false') ? false : true
    e.target.setAttribute('aria-expanded', !value)
  }
  
  return (
    <div className="anchors__side share">
      <button className="share__button" onClick={(e) => handleClick(e)} aria-expanded="false" aria-controls="share-dropdown">
        <ShareIcon aria-hidden="true" className="icon-bg" yera={year} />
        <span className="sr-only">Partager</span>
      </button>

      <div className={`share__dropdown ${isOpen ? 'is-open' : ''}`} id="share-dropdown">
        <ul>
          <li>
            <a className="share__item" href={`${encodeURI(`https://twitter.com/share?text=${pageTxt}&url=${pageUrl}`).replace(/%20/g, "+")}`} target="_blank" rel="noopener noreferrer">
              <TwitterIcon aria-hidden="true" className="icon-bg" />
              <span className="sr-only">Partager sur Twitter</span>
            </a>
          </li>
          <li>
            <a className="share__item" href={`${encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`).replace(/%20/g, "+")}`} target="_blank" rel="noopener noreferrer">
              <FacebookIcon aria-hidden="true" className="icon-bg" />
              <span className="sr-only">Partager sur Facebook</span>
            </a>
          </li>
          <li>
            <a className="share__item" href={`${encodeURI(`https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}&title=${pageTxt}&source=bigyouth`).replace(/%20/g, "+")}`} target="_blank" rel="noopener noreferrer">
              <LinkedinIcon aria-hidden="true" className="icon-bg" />
              <span className="sr-only">Partager sur Linkedin</span>
            </a>
          </li>
          <li>
            <button className="share__item" onClick={() => copyLink()}>
              <LinkIcon aria-hidden="true" className="icon-bg" />
              <span className="sr-only">Copier le lien</span>
              
              <span className={`share__confirm ${activeMessageCopy ? 'is-visible' : ''}`}>Adresse copiée dans le presse-papier</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Share